import React, { useEffect, useState } from "react";
import * as s from "./index.module.scss";
import {
  footerBar,
  footerSection,
  iconContainer,
  linksContainer,
  linksContainerA,
  linksRowContainer,
  linksRowContainerIcons,
  numberC,
  productsContainer,
} from "./index.module.scss";
import Twitter from "../../../images/social-media-icons/iQ-icons-social-Twitter-WHT.svg";
import LinkedIn from "../../../images/social-media-icons/iQ-icons-social-LinkedIn-WHT.svg";
import Instagram from "../../../images/social-media-icons/iQ-icons-social-Instagram-WHT.svg";
import Facebook from "../../../images/social-media-icons/iQ-icons-social-Facebook-WHT.svg";
import HorizontalLogo from "../../../images/logo-horizontal-white.svg";
import HorizontalLogoFr from "../../../images/logos/fr-logos/EspaceiQ_horizontal_white.svg";
import CloseButton from "../../../images/design-components/Less_1_WHT.svg";
import get from "lodash/get";
import { productLink } from "../Header/index.module.scss";
import { Link } from "gatsby";
import i18n from "../../../utils/i18n";
import { memberPortalUrl } from "../../../../config";
import { productPageCategories } from "../Header/HeaderDesktop";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import SubmitButton from "../../../SubmitButton";
import cx from "classnames";
import submitFormToHubSpot from "../../../utils/submitFormToHubSpot";

const Footer = ({ pages, activeDocMeta, subscriptionModalData }) => {
  // ------ Form Logic START --------
  const [progress, setProgress] = useState(false);
  const [sent, setSent] = useState(false);

  const validationObject = {
    firstName: Yup.string().required(i18n.t("Required")),
    lastName: Yup.string().required(i18n.t("Required")),
    email: Yup.string()
      .email(i18n.t("InvalidEmailAddress"))
      .required(i18n.t("Required")),
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: Yup.object(validationObject),
    onSubmit: async (values) => {
      await handleSubscription(values);
    },
  });

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (
      !subscriptionModalData?.blog_page_modal_display_delay ||
      typeof window === "undefined"
    )
      return;

    const pathname = window.location.pathname;

    if (!pathname.startsWith("/blog") && !pathname.startsWith("/locations"))
      return;

    // @john I just added the code here.
    // If the user closes the modal that's triggered from this code, the modal should not be displayed automatically again.
    // save some value to local storage and check if that exists before displaying the modal

    const timer = setTimeout(() => {
      if (
        !(
          localStorage.getItem("subscriptionModalClosed") ||
          localStorage.getItem("subscriptionModalSubmitted")
        )
      ) {
        setShow(true);
      }
    }, subscriptionModalData?.blog_page_modal_display_delay * 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleSubscription = async (values) => {
    // const res = await formik.validateForm();
    // if (Object.entries(res).length > 0) {
    //   return;
    // }

    const res = await formik.validateForm();
    if (Object.entries(res).length > 0) {
      return;
    }

    setProgress(true);

    const data = [
      { name: "firstname", value: values?.firstName },
      { name: "lastname", value: values?.lastName },
      { name: "email", value: values?.email },
      {
        name: "most_recent_source",
        value: "Website - Newsletter Subscription",
      },
    ];

    const formId =
      i18n.t("lang") === "fr"
        ? "0bd0fa3c-5049-4ebe-8986-d4353008d8e7"
        : "b13304be-df45-4a95-b5d3-df7e6f6ce49f";

    await submitFormToHubSpot(formId, data)
      .then((res) => {
        //
      })
      .catch((e) => {})
      .finally(() => {
        setProgress(false);
        setSent(true);
      });

    localStorage.setItem("subscriptionModalSubmitted", "true");
  };

  // ------ Form Logic END --------

  // ------ Modal Logic START --------

  const handleClose = () => {
    const pathname = window.location.pathname;

    if (!pathname.startsWith("/blog") && !pathname.startsWith("/locations")) {
      setShow(false);
    } else {
      setShow(false);
      localStorage.setItem("subscriptionModalClosed", "true");
    }
  };

  // ------ Modal Logic END --------

  const isEnglish = get(activeDocMeta, "lang", "") !== "fr-ca";

  const Logo =
    get(activeDocMeta, "lang", "") === "en-us"
      ? HorizontalLogo
      : HorizontalLogoFr;

  const productPages = get(pages, "productPages", []);
  const customPages = get(pages, "customPages", []);
  const footerMenu1Pages = customPages
    .filter((page) => get(page, "dataRaw.show_in_footer_menu_1", false))
    .sort(function (a, b) {
      return (
        get(a, "dataRaw.footer_menu_1_order", 0) -
        get(b, "dataRaw.footer_menu_1_order", 0)
      );
    });
  const footerMenu2Pages = customPages
    .filter((page) => get(page, "dataRaw.show_in_footer_menu_2", false))
    .sort(function (a, b) {
      return (
        get(a, "dataRaw.footer_menu_2_order", 0) -
        get(b, "dataRaw.footer_menu_2_order", 0)
      );
    });
  const footerMenu3Pages = customPages
    .filter((page) => get(page, "dataRaw.show_in_footer_menu_3", false))
    .sort(function (a, b) {
      return (
        get(a, "dataRaw.footer_menu_3_order", 0) -
        get(b, "dataRaw.footer_menu_3_order", 0)
      );
    });

  // @Ekaterina: Footer popup
  return (
    <>
      <Modal
        centered
        dialogClassName={s.dialogModal}
        className={s.subscriptionModal}
        show={show}
        onHide={() => handleClose()}
      >
        <button
          className={`btn-as-link ${s.closeButton}`}
          onClick={() => handleClose()}
        >
          <img alt="" src={CloseButton} />
        </button>
        <div className={s.subscriptionModal__content}>
          <h3 className={"mb-4 pb-2"}>
            {get(subscriptionModalData, "subscription_form_title")}
          </h3>
          <p className={"mb-4"}>
            {get(subscriptionModalData, "subscription_form_description")}
          </p>
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              id="firstName"
              name="firstName"
              type="text"
              className={cx("form-control mb-lg-3 mb-2", {
                [s.formError]:
                  get(formik, "touched.firstName", false) &&
                  get(formik, "errors.firstName", false),
              })}
              placeholder={`${i18n.t("profilePage.FirstName")}*`}
              {...formik.getFieldProps("firstName")}
            />
            <input
              id="lastName"
              name="lastName"
              type="text"
              className={cx("form-control mb-lg-3 mb-2", {
                [s.formError]:
                  get(formik, "touched.lastName", false) &&
                  get(formik, "errors.lastName", false),
              })}
              placeholder={`${i18n.t("profilePage.LastName")}*`}
              {...formik.getFieldProps("lastName")}
            />
            <input
              id="email"
              name="email"
              type="text"
              className={cx("form-control mb-4", {
                [s.formError]:
                  get(formik, "touched.email", false) &&
                  get(formik, "errors.email", false),
              })}
              placeholder={`${i18n.t("profilePage.EmailAddress")}*`}
              {...formik.getFieldProps("email")}
            />
            <SubmitButton
              variant={"outline-light"}
              label={i18n.t("Sign Up")}
              progress={progress}
              success={sent}
              onClick={() => formik.handleSubmit()}
              className={""}
            />

            {sent && (
              <p className="mt-4">{i18n.t("SubscriptionSuccessMessage")}</p>
            )}
          </form>
        </div>
      </Modal>
      <footer
        className={`d-print-none container-fluid bg-primary ${footerSection}`}
      >
        <div className="container-xxl d-flex flex-column py-3">
          <div className={`row d-lg-flex ${linksRowContainer}`}>
            <div className={`col-sm-4 col-lg-2 py-0 ${linksContainer}`}>
              <h5 className={`mb-4 text-white d-none d-lg-block`}>
                {i18n.t("whyIq")}
              </h5>
              <Link
                to={`${isEnglish ? "" : "/fr"}/why-iq/about-iq`}
                title={"About"}
              >
                About
              </Link>
              {footerMenu1Pages.map((page) => (
                <Link
                  key={page.id}
                  to={page.url}
                  title={get(page, "data.link_label", "N/A")}
                >
                  {get(page, "data.link_label", "N/A")}
                </Link>
              ))}
            </div>

            <div className={`col-lg-6 d-lg-block d-none ${productsContainer}`}>
              <h5 className={`mb-4 text-white`}>{i18n.t("Products")}</h5>
              <div className="row">
                {productPageCategories.map((category = "") => {
                  const titleArray = category.split(" ");
                  return (
                    <div className={"col-lg-4"} key={category}>
                      <h3>{get(titleArray, "[0]", category)}</h3>
                      {productPages
                        .filter((page) => page.data.product_type === category)
                        .map((page) => (
                          <Link
                            to={page.url}
                            className={productLink}
                            key={page.id}
                            title={get(page, "data.menu_link_name", "")}
                          >
                            {get(page, "data.menu_link_name", "")}
                          </Link>
                        ))}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={`col-sm-8 col-lg-2 py-0 row ${linksContainer}`}>
              <div
                className={`col-sm-6 col-lg-auto pt-3 py-sm-0  ${linksContainer} ${linksContainerA}`}
              >
                <h5 className={`text-white d-none d-lg-block mb-4`}>
                  {i18n.t("Members")}
                </h5>
                <Link
                  to={`${i18n.t("/lang/")}products`}
                  className={"mt-3 my-4 my-sm-0 d-lg-none"}
                >
                  {i18n.t("Products")}
                </Link>
                <a href={memberPortalUrl} target={"_blank"} rel="noreferrer">
                  Portal
                </a>
                {footerMenu2Pages.map((page) => (
                  <Link
                    key={page.id}
                    to={page.url}
                    title={get(page, "data.link_label", "N/A")}
                  >
                    {get(page, "data.link_label", "N/A")}
                  </Link>
                ))}
                <Link
                  to={`${i18n.t("/lang/")}products/additional-services`}
                  title={i18n.t("AdditionalServices")}
                  className={"d-lg-none"}
                >
                  {i18n.t("AdditionalServices")}
                </Link>
              </div>
              <div className="col-sm-6 col-lg-auto">
                <h5 className="text-white p-0 mt-5 mb-4 d-lg-inline-block d-none">
                  {i18n.t("Partnerships")}
                  <br />
                </h5>
                {footerMenu3Pages.map((page) => (
                  <Link
                    key={page.id}
                    to={page.url}
                    title={get(page, "data.link_label", "N/A")}
                  >
                    {get(page, "data.link_label", "N/A")}
                  </Link>
                ))}
              </div>
            </div>

            <div
              className={`col-lg-2 d-lg-flex py-0 flex-column d-none ${linksContainer}`}
            >
              <h5 className="text-white p-0 text-nowrap mb-4">
                {i18n.t("Connect")}
                <br />
              </h5>
              <a
                className={`iQ-phone-Number text-white text-decoration-none d-block pb-0 mb-3 ${numberC}`}
                href="tel:18009333176"
              >
                1 (800) 933-3176
              </a>
              <p>
                150 King St W <br /> 2nd Floor <br /> Toronto, ON <br />
                M5H 1J9
              </p>

              {/*appear on desktop only*/}
              <div className="mt-auto">
                <Button
                  className={s.subscribeButton}
                  variant="light"
                  onClick={() => setShow(true)}
                >
                  <span className={s.subscribeButton__text}>
                    {subscriptionModalData.subscription_footer_button_label}
                  </span>
                </Button>
              </div>
              <div className="d-md-flex d-none justify-content-sm-start justify-content-center mt-4">
                <SocialLinks />
              </div>
            </div>

            {/*appear on phone only*/}
            <div className={`d-sm-none col ${linksContainer}`}>
              <div>
                <p className="text-white p-0 d-lg-block d-inline d-sm-none">
                  <strong>{i18n.t("ContactUs")}</strong>
                  <br />
                </p>
                <a
                  className="text-white text-decoration-none d-block pb-2 pt-2 iQ-phone-Number"
                  href="tel:18009333176"
                >
                  1 (800) 933-3176
                </a>
              </div>
            </div>
          </div>

          {/*appear on phone and tablet */}
          <div
            className={`row d-flex d-lg-none ${linksRowContainer} ${linksRowContainerIcons}`}
          >
            <div className="col-12 col-sm-4 mb-3 mb-sm-0">
              <Button
                className={s.subscribeButton}
                variant="light"
                onClick={() => setShow(true)}
              >
                <span className={s.subscribeButton__text}>
                  {subscriptionModalData.subscription_footer_button_label}
                </span>
              </Button>
            </div>
            <div className="col d-flex align-items-center">
              <SocialLinks />
            </div>
            <div className="col d-sm-flex justify-content-end align-items-center d-none">
              <a
                href={"tel:18009333176"}
                className={`text-white ms-auto d-none d-sm-inline-block iQ-phone-Number`}
              >
                1 (800) 933-3176
              </a>
            </div>
          </div>

          <div
            className={`row border-top border-bottom border-white mt-3 ${footerBar}`}
          >
            <div className="col d-flex flex-column flex-sm-row align-items-center justify-content-center">
              <Link
                className="text-white text-decoration-none p-2 p-sm-0 text-center menu-item accessibility"
                to={`${isEnglish ? "" : "/fr"}/accessibility`}
                title={i18n.t("Accessibility")}
              >
                {i18n.t("Accessibility")}
              </Link>
              <p className="text-white my-0 mx-3 d-none d-sm-inline-block">|</p>
              <Link
                className="text-white text-decoration-none p-2 p-sm-0 text-center"
                to={`${isEnglish ? "" : "/fr"}/privacy-policy`}
                title={i18n.t("privacyPolicy")}
              >
                {i18n.t("privacyPolicy")}
              </Link>
            </div>
          </div>

          <div className="row my-4 py-3 justify-content-between">
            <div className="col-lg-6 order-lg-1 mb-5 mb-lg-0 d-flex justify-content-center align-items-lg-end justify-content-lg-end">
              <p
                className={`text-white p-lg-0 my-auto text-sm-end text-center`}
              >
                {new Date().getFullYear()} &#169; iQ Office Suites Holdings Inc.
              </p>
            </div>
            <div className="col-lg-6 order-lg-0 d-flex justify-content-center justify-content-lg-start">
              <Link to={isEnglish ? "/" : "/fr"} title={"Home page"}>
                <img alt="iQ Offices" src={Logo} width={192} />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

export const SocialLinks = () => {
  return (
    <>
      <a
        href="https://www.linkedin.com/company/iq-offices/"
        target={"_blank"}
        rel="noreferrer"
      >
        <img alt="LinkedIn" src={LinkedIn} className={iconContainer} />
      </a>
      <a
        href="https://instagram.com/iqoffices/"
        target={"_blank"}
        rel="noreferrer"
      >
        <img alt="Instagram" src={Instagram} className={iconContainer} />
      </a>
      <a
        href="https://www.facebook.com/iqoffices.canada"
        target={"_blank"}
        rel="noreferrer"
      >
        <img alt="Facebook" src={Facebook} className={iconContainer} />
      </a>
    </>
  );
};
