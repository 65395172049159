import React, { useEffect, useState } from "react";
import get from "lodash/get";
import * as s from "./index.module.scss";
import {
  aamSignInButton,
  dropdownsMobile,
  locationsEntry,
  locationsEntryImage,
  locationsListContainer,
  locationsMapContainer,
  locationsSelectorDesktop,
  locationsSelectorDesktop__buttonsSection,
  locationsSelectorSection,
} from "./index.module.scss";
import { Button } from "react-bootstrap";
import { GoogleMap, Marker } from "../../components/GoogleMap";
import DropdownLocation from "../../components/DropdownLocation/DropdownLocation";
import iqApi from "../../services/iqApi";
import { locationCenters, locationOptions } from "./_config";
import { memberPortalUrl } from "../../../config";
import CityList from "../../components/LocationsPage/CityList";
import { StringParam, useQueryParam } from "use-query-params";
import i18n from "../../utils/i18n";
import ProgressDots from "../../components/ProgressDots";
import cx from "classnames";

const SearchVirtualOffices = ({ next = () => null }) => {
  // LOCATION props
  const [location = "Toronto", setLocation] = useQueryParam(
    "location",
    StringParam,
  );

  // Results
  const [offices, setOffices] = useState([]);
  const [locationHovered, setLocationHovered] = useState("");

  const [mapCenter, setCenter] = useState({
    lat: 43.6508797,
    lng: -79.3834781,
  });

  useEffect(() => {
    const match = locationCenters.find(
      (center) => center.location === location,
    );
    if (match) {
      setCenter(match.center);
    }
  }, [location]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    iqApi("get-offices").then((res) => {
      const type = get(res, "type", "error");
      const data = get(res, "offices", []);
      if (type === "success") {
        const formattedData = data
          .filter((item) => {
            const description = get(item, "description", "");
            const descriptionArray = description.split(" | ");

            const latLngString = get(descriptionArray, "[1]", "");
            const latLngArray = latLngString.split(",");
            const invalidData =
              !latLngArray ||
              latLngArray.length <= 0 ||
              !descriptionArray ||
              descriptionArray.length <= 1;
            return item.isOpen && !invalidData;
          })
          .map((item, idx) => {
            const description = get(item, "description", "");
            const descriptionArray = description.split(" | ");

            const latLngString = get(descriptionArray, "[1]", "");
            const latLngArray = latLngString.split(",");

            return {
              ...item,
              title: get(descriptionArray, "[0]", ""),
              lat: get(latLngArray, "[0]", 0),
              lng: get(latLngArray, "[1]", 0),
              order: descriptionArray?.[2]?.trim() || 99,
            };
          })
          .sort((a, b) => a.order - b.order);
        setOffices(formattedData);

        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <div className={`container-xxl ${locationsSelectorSection}`}>
        <h5>{i18n.t(`profilePage.VirtualOffices`)}</h5>

        <div className={"d-none d-md-block"}>
          {/*// DESKTOP - 4 locations, team size dropdown + start & end date**/}
          <div className={`${locationsSelectorDesktop}`}>
            {/*& > div:nth-child(1)*/}
            <div>
              <CityList />
            </div>
            <div
              className={`d-xl-block d-none ${locationsSelectorDesktop__buttonsSection}`}
            >
              <div>
                <Button
                  onClick={() => window.open(memberPortalUrl, "_blank").focus()}
                  className={`${aamSignInButton} ${s.aamSignInButton__virtualOffices}`}
                  variant="info"
                >
                  <span>{i18n.t("AlreadyMember")}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={"d-block d-md-none"}>
          <div className={dropdownsMobile}>
            <div className="d-flex flex-column">
              <div className="w-100">
                <DropdownLocation
                  location={location}
                  setLocation={setLocation}
                  locationOptions={locationOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Map + LocationsList START*/}
      <div className={`container-xxl`}>
        <div className={`row`}>
          <div
            className={cx(
              locationsMapContainer,
              `col-lg-6 offset-lg-0 order-lg-1`,
              `col-md-8 offset-md-2`,
            )}
          >
            <GoogleMap center={mapCenter}>
              {offices
                .filter((office) => office.city === location)
                .map(({ _id, lat, lng, title }) => {
                  return (
                    <Marker
                      key={_id}
                      lat={lat}
                      lng={lng}
                      name={title}
                      show={_id === locationHovered ? true : ""}
                    />
                  );
                })}
            </GoogleMap>
          </div>
          <div
            className={`col-lg-6 order-lg-0 d-flex flex-column ${locationsListContainer}`}
          >
            <>
              {loading ? (
                <div className="position-relative">
                  <ProgressDots />
                </div>
              ) : (
                offices
                  .filter((office) => office.city === location)
                  .map((office) => {
                    const { _id, title, image = "" } = office;
                    return (
                      <div
                        key={_id}
                        className={`${locationsEntry} cursor-pointer`}
                        onMouseEnter={() => setLocationHovered(_id)}
                        onClick={() => next(office)}
                        onMouseLeave={() => {
                          setLocationHovered("");
                        }}
                      >
                        <div className="row">
                          <div className="col-sm-6">
                            <div className={locationsEntryImage}>
                              <img alt={title} src={`https:${image}`} />
                            </div>
                          </div>
                          <div className="col-sm-6 d-flex flex-column">
                            <h5>{title}</h5>
                          </div>
                        </div>
                      </div>
                    );
                  })
              )}
            </>
          </div>
        </div>
      </div>
      {/*Map + LocationsList END*/}
    </>
  );
};

export default SearchVirtualOffices;
